<script setup>
    import {ref, onMounted} from 'vue'
    import {useRoute, useRouter} from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import {useLectureStore} from "../../stores/lecture/lectureStore";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import lectureService from "../../services/lecture/lectureService";
    import _ from 'lodash'
    import fileService from "../../../../services/fileService";
    import KSAInputWithBytes from "../../../../components/ui-components/input/KSAInputWithBytes.vue";
    import LectureSurveySearchModal from "../../components/modal/lecture/LectureSurveySearchModal.vue";
    import surveyService from "../../services/lecture/surveyService";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const lectureStore = useLectureStore()

    onMounted(() => {
        const { id } = route.params;
        if(!id) {
            router.push({name: 'LectureList'})
        } else {
            retrieveDetail(id)
        }
    })

    const input = ref({
        lectId: 0,
        lectGb: "01", // 강의구분
        lectOpenYn: "N", // 강의공개여부
        lectThombName: "", // 강의썸네일명
        lectThombSize: "", // 강의썸네일용량
        lectThombDir: "", // 강의썸네일위치
        lectName: "", //강의명
        regTermGb: "01", // 신청기간구분
        regStartYmd: "", // 신청시작일자
        regEndYmd: "", // 신청종료일자
        lectTermGb: "01", // 교육기간구분
        lectTerm: 0, // 교육기간
        lectStartYmd: "", // 교육시작일자
        lectEndYmd: "", // 교육종료일자
        finCriteriaGb: "01", // 수료기준구분
        finishCriteria: 0, // 수료기준
        allowIpList: "", // 허용 IP
        note: "", // 기타
        lectDesc: "", // 강의설명
        lectIntro: "", // 과정소개
        repreLectYn: "N", // 대표강의여부
        surveyId: "", // 대표강의여부
        ticketOfferGb: "01", // 수강권제공구분
        intvTermGb: "01", // 면접기간구분
        intvStartYmd: "", // 면접시작일자
        intvEndYmd: "", // 면접종료일자
        lectHour: 0, // 교육시간_시
        lectMin: 0, // 교육시간_분
        lectPlace: "", // 교육장소
        lectTrgt: "ALL", // 교육대상자
        regMemOpenYn: "Y", // 신청인원공개여부
        selMemOpenYn: "Y", // 선발인원공개여부
        limitMemYn: false, // 제한인원여부 Y, N
        limitMemNum: 0, // 제한인원
        userId: 0, // 사용자아이디
        survey: {} // 만족도
    })

    const inputSurvey = ref({
        surveyId: 0,
        title: "",
    })

    const inputError = ref({
        lectThombName: '',
        lectName: '',
        lectTerm: '',
        lectIntro: '',
        allowIpList: '',
    })

    const editorRef = ref(null)

    const modal = ref(null)
    const modalContent = ref('');
    const surveySearchModal = ref(null)

    const retrieveDetail = (lectId) => {
        loadingStore.loading = true;
        lectureService.retrieveLectureDetail(lectId)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    input.value = result;
                    input.value.limitMemYn = input.value.limitMemYn === 'N';
                    retrieveSurveyDetail(input.value.survey.surveyId, lectId);
                    if(input.value.lectIntro) {
                        setTimeout(()=>{
                            editorRef.value.init(input.value.lectIntro);
                        }, 100)
                    }
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    const retrieveSurveyDetail = (surveyId, lectId) => {
        loadingStore.loading = true;
        surveyService.retrieveSurveyPaper(surveyId, lectId)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    inputSurvey.value = result;
                }
            }).catch( (res) =>  {
            // console.log(res);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    const uploadThumbFile = (type) => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];

            let paramObj = {
                container: 'public',
                folder: 'lecture',
                file,
            }
            fileService.uploadFile(paramObj)
                .then((res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        if(type === 'thumb') {
                            input.value.lectThombName = result.originalFilename;
                            input.value.lectThombSize = result.fileSize;
                            input.value.lectThombDir = result.blobUrl;
                        } else {
                            input.value.oneMovName = result.originalFilename;
                            input.value.oneMovSize = result.fileSize;
                            input.value.oneMovDir = result.blobUrl;
                        }
                    }
                })
                .catch( (error) =>  {
                    console.log(error);
                });
        });
    }

    const removeThumbFile = (type) => {
        if(type === 'thumb') {
            input.value.lectThombName = '';
            input.value.lectThombSize = '';
            input.value.lectThombDir = '';
        } else {
            input.value.oneMovName = '';
            input.value.oneMovSize = '';
            input.value.oneMovDir = '';
        }
    }

    const update = () => {
        const paramObj = _.cloneDeep(input.value);
        const {
            lectGb,
            lectThombName,
            lectName,
            lectTermGb,
            lectTerm,
            lectStartYmd,
            lectEndYmd,
            lectIntro,
            lectHour,
            lectMin,
            lectPlace,
            allowIpList,
            survey
        } = paramObj;
        let isInvalid = false;

        inputError.value = {};
        if (!lectThombName) {
            inputError.value.lectThombName = '이미지를 첨부하세요.';
            isInvalid = true;
        }
        if (!lectName) {
            inputError.value.lectName = '강의명을 입력하세요.';
            isInvalid = true;
        }
        if (lectTermGb === '01' && (lectTerm === null || lectTerm === '')) {
            inputError.value.lectTerm = '교육기간을 입력하세요.';
            isInvalid = true;
        } else if (lectTermGb === '02' && (!lectStartYmd || !lectEndYmd)) {
            inputError.value.lectTerm = '교육기간을 입력하세요.';
            isInvalid = true;
        }
        if (!lectIntro) {
            inputError.value.lectIntro = '과정 소개를 입력하세요.';
            isInvalid = true;
        }

        if (lectGb === '02') {
            // if ((lectHour === null || lectHour === '') || (lectMin === null || lectMin === '')) {
            //     inputError.value.lectHour = '교육 시간을 입력하세요.';
            //     isInvalid = true;
            // }
            if (!lectPlace) {
                inputError.value.lectPlace = '교육 장소를 입력하세요.';
                isInvalid = true;
            }
        }

        // IP 입력값이 존재하는 경우, 형식에 맞는지 validation. 예 255.255.0.0/16
        if (allowIpList) {
          let result = false;
          const cidrs = allowIpList.split(',').map(cidr => cidr.trim());
          result = cidrs.every(isValidCidr);
          if (!result) {
            inputError.value.allowIpList = 'IP 형식을 맞춰서 입력해주세요.';
            // 추가 로직 작성
            isInvalid = true;
          }
        }

        if (!isInvalid) {
            paramObj.limitMemYn = paramObj.limitMemYn ? 'N' : 'Y';
            paramObj.limitMemNum = Number(paramObj.limitMemNum);
            paramObj.lectTerm = Number(paramObj.lectTerm);
            paramObj.lectHour = Number(paramObj.lectHour);
            paramObj.lectMin = Number(paramObj.lectMin);
            paramObj.finishCriteria = Number(paramObj.finishCriteria);
            paramObj.survey = inputSurvey.value;

            loadingStore.loading = true;
            lectureService.updateLecture(paramObj)
                .then(async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if (res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        modalContent.value = ["변경사항을 저장했습니다."];
                        const ok = await modal.value.show();
                        if(ok) {
                            goBack();
                        }
                    } else {
                    }
                }).catch((res) => {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    // CIDR 유효성 검사 함수
    const isValidCidr = cidr => {
      // 예 255.255.0.0/16
      const cidrPattern = /^([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])$/;
      if (cidrPattern.test(cidr)) {
        const [ip, prefix] = cidr.split('/');
        return ip.split('.').every(part => {
          const num = Number(part);
          return num >= 0 && num <= 255;
        });
      }
      return false;
    };

    const goBack = () => {
        router.push({name: 'LectureDetail', params: {id: input.value.lectId}});
    }

    const onClickSurveyModal = async () => {
        surveySearchModal.value.init();
        const result = await surveySearchModal.value.show();
        if( result ) {
          inputSurvey.value = result;
        }
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb"/>
            <div class="content">
                <div class="sub-title">
                    <h3>강의 수정</h3>
                    <p><span>* 표시는 필수 입력사항</span> 입니다.</p>
                </div>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px"/>
                            <col/>
                        </colgroup>
                        <tr>
                            <th>구분</th>
                            <td>
                                <KSARadioButton class="mr-md" name="lectGb" label="온라인 강의" v-model="input.lectGb"
                                                value="01"/>
                                <KSARadioButton class="mr-md" name="lectGb" label="오프라인 강의" v-model="input.lectGb"
                                                value="02"/>
                            </td>
                        </tr>
                        <tr>
                            <th>상태</th>
                            <td>
                                <KSARadioButton class="mr-md" name="lectOpenYn" label="공개" v-model="input.lectOpenYn"
                                                value="Y" :disabled="input.lectureIndexCount === 0"/>
                                <KSARadioButton class="mr-md" name="lectOpenYn" label="비공개" v-model="input.lectOpenYn"
                                                value="N" :disabled="input.lectureIndexCount === 0"/>
                                <p class="mt-xs" v-if="input.lectureIndexCount === 0">* 차시가 없는 강의는 공개할 수 없습니다.</p>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">이미지</th>
                            <td class="text-box">
                                <div class="grid file">
                                    <KSAPrimaryButton text="파일 선택" @click="uploadThumbFile('thumb')" />
                                    <KSAInput class="input icon" placeholder="파일 선택(398px X 284px 사이즈 이미지를 권장합니다.)" v-model="input.lectThombName" disabled />
                                    <button class="delete" :disabled="!input.lectThombName" @click="removeThumbFile('thumb')"><i></i></button>
                                </div>
                                <small>최대 200MB 까지 첨부 가능</small>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectThombName }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '01'">
                            <th class="">미리보기 영상</th>
                            <td class="text-box">
                                <div class="grid file">
                                    <KSAPrimaryButton text="파일 선택" @click="uploadThumbFile('onemov')" />
                                    <KSAInput class="input icon" placeholder="파일 선택" v-model="input.oneMovName" disabled />
                                    <button class="delete" :disabled="!input.oneMovName" @click="removeThumbFile('onemov')"><i></i></button>
                                </div>
                                <small>최대 200MB 까지 첨부 가능</small>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.oneMovName }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">강의명</th>
                            <td>
                                <KSAInputWithBytes class="text-box" v-model="input.lectName" :msg="inputError.lectName" max-bytes="50"/>
                            </td>
                        </tr>
                        <tr>
                            <th>신청 기간</th>
                            <td class="text-box">
                                <KSARadioButton class="mr-md" name="regTermGb" label="상시" v-model="input.regTermGb"
                                                value="01"/>
                                <KSARadioButton class="mr-md" name="regTermGb" label="기간선택" v-model="input.regTermGb"
                                                value="02"/>
                                <div style="display: inline-block;" v-if="input.regTermGb === '02'">
                                    <KSADatepickerTerms
                                        :startDate="input.regStartYmd"
                                        :endDate="input.regEndYmd"
                                        @update:startDate="(value)=> input.regStartYmd = value"
                                        @update:endDate="(value)=> input.regEndYmd = value"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'">
                            <th>면접 기간</th>
                            <td>
                                <KSARadioButton class="mr-md" name="intvTermGb" label="상시" v-model="input.intvTermGb"
                                                value="01"/>
                                <KSARadioButton class="mr-md" name="intvTermGb" label="기간선택" v-model="input.intvTermGb"
                                                value="02"/>
                                <div style="display: inline-block;" v-if="input.intvTermGb === '02'">
                                    <KSADatepickerTerms
                                        :startDate="input.intvStartYmd"
                                        :endDate="input.intvEndYmd"
                                        @update:startDate="(value)=> input.intvStartYmd = value"
                                        @update:endDate="(value)=> input.intvEndYmd = value"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">교육 기간</th>
                            <td>
                                <KSARadioButton class="mr-md" name="lectTermGb" label="신청일기준" v-model="input.lectTermGb"
                                                value="01"/>
                                <KSARadioButton class="mr-md" name="lectTermGb" label="기간선택" v-model="input.lectTermGb"
                                                value="02"/>
                                <div style="display: inline-block;" v-if="input.lectTermGb === '01'">
                                    <div class="grid">
                                        신청일로부터
                                        <KSAInput style="width: 160px;" class="text-box ml-sm" v-model="input.lectTerm"
                                                  max-length="4"/>
                                        일
                                    </div>
                                </div>
                                <div style="display: inline-block;" v-else>
                                    <KSADatepickerTerms
                                        :startDate="input.lectStartYmd"
                                        :endDate="input.lectEndYmd"
                                        @update:startDate="(value)=> input.lectStartYmd = value"
                                        @update:endDate="(value)=> input.lectEndYmd = value"
                                    />
                                </div>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectTerm }}</small>
                                </div>
                            </td>
                        </tr>
<!--                        <tr v-if="input.lectGb === '02'">-->
<!--                            <th class="mark">교육 시간</th>-->
<!--                            <td>-->
<!--                                <div class="grid mt-xs">-->
<!--                                    <KSAInput style="width: 160px;" v-model="input.lectHour" max-length="3"/>-->
<!--                                    시간-->
<!--                                    <KSAInput style="width: 160px;" class="ml-sm" v-model="input.lectMin"-->
<!--                                              max-length="2"/>-->
<!--                                    분-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <small style="color:#E71414;">{{ inputError.lectHour }}</small>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
                        <tr v-if="input.lectGb === '02'">
                            <th class="mark">교육 장소</th>
                            <td>
                                <KSAInput class="text-box" v-model="input.lectPlace" :msg="inputError.lectPlace"
                                          max-length="50"/>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'">
                            <th>교육 대상자</th>
                            <td>
                                <KSARadioButton class="mr-md" name="lectTrgt" label="전체" v-model="input.lectTrgt"
                                                value="ALL"/>
                                <KSARadioButton class="mr-md" name="lectTrgt" label="재직자" v-model="input.lectTrgt"
                                                value="COMPANY"/>
                                <KSARadioButton class="mr-md" name="lectTrgt" label="대학생" v-model="input.lectTrgt"
                                                value="STUDENT"/>
                            </td>
                        </tr>
                        <tr>
                            <th>수료 기준</th>
                            <td>
                                <KSARadioButton class="mr-md" name="finCriteriaGb" label="없음"
                                                v-model="input.finCriteriaGb" value="01"/>
                                <KSARadioButton class="mr-md" name="finCriteriaGb" label="진도율 기준"
                                                v-model="input.finCriteriaGb" value="02"/>
                                <KSARadioButton class="mr-md" name="finCriteriaGb" label="Pass/Fail"
                                                v-model="input.finCriteriaGb" value="03"/>
                                <div style="display: inline-block;" v-if="input.finCriteriaGb === '02'">
                                    <div class="grid">
                                        교육기간 내 진도율
                                        <KSAInput style="width: 160px" class="text-box ml-sm"
                                                  v-model="input.finishCriteria" max-length="3"/>
                                        % 이상
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'">
                            <th>신청 인원</th>
                            <td>
                                <KSARadioButton class="mr-md" name="regMemOpenYn" label="공개"
                                                v-model="input.regMemOpenYn" value="Y"/>
                                <KSARadioButton class="mr-md" name="regMemOpenYn" label="비공개"
                                                v-model="input.regMemOpenYn" value="N"/>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '02'">
                            <th>선발 인원</th>
                            <td>
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="공개"
                                                v-model="input.selMemOpenYn" value="Y"/>
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="비공개"
                                                v-model="input.selMemOpenYn" value="N"/>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '01'">
                            <th>수강권 제공</th>
                            <td>
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="수강 신청 즉시 수강권 제공"
                                                v-model="input.ticketOfferGb" value="01"/>
                                <KSARadioButton class="mr-md" name="ticketOfferGb" label="수강 신청 인원 중 선정하여 수강권 제공"
                                                v-model="input.ticketOfferGb" value="02"/>
                            </td>
                        </tr>
                        <tr>
                            <th>제한 인원</th>
                            <td class="align-center">
                                <KSAInput style="width: 160px;" class="text-box" v-model="input.limitMemNum" max-length="3" :disabled="input.limitMemYn"/>명
                                <KSACheckBox class="ml-xs" label="없음" v-model="input.limitMemYn"/>
                            </td>
                        </tr>
                        <tr v-if="input.lectGb === '01'">
                            <th>수강중 인원</th>
                            <td>
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="공개" v-model="input.selMemOpenYn" value="Y" />
                                <KSARadioButton class="mr-md" name="selMemOpenYn" label="비공개" v-model="input.selMemOpenYn" value="N" />
                            </td>
                        </tr>
                        <tr>
                            <th>허용IP</th>
                            <td>
                              설정한 IP주소에서만 보안PDF를 볼 수 있게 설정합니다.(예 255.255.0.0/16)<br>
                              입력폼내 콤마(,)로 구분하여 여러 개의 IP주소를 설정할 수 있습니다.
                              <KSAInput class="text-box" v-model="input.allowIpList" :msg="inputError.allowIpList" max-length="2000"/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타</th>
                            <td>
                                <KSAInput class="text-box" v-model="input.note" max-length="2000"/>
                            </td>
                        </tr>
                        <tr>
                            <th>강의 설명</th>
                            <td>
                                <KSAInput class="text-box" v-model="input.lectDesc" max-length="2000"/>
                            </td>
                        </tr>
                        <tr>
                            <th class="mark">과정 소개</th>
                            <td>
                                <KSACKEditor ref="editorRef" v-model="input.lectIntro" container="public"/>
                                <div>
                                    <small style="color:#E71414;">{{ inputError.lectIntro }}</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>만족도 평가</th>
                            <td class="text-box">
                                <div class="grid">
                                    <KSAInput style="max-width: 1000px; width: 100%" class="text-box" v-model="inputSurvey.title" max-length="2000" disabled />
                                    <KSAPrimaryButton text="검색" class="ml-xs" @click="onClickSurveyModal" />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="btn-wrap space-between">
                    <KSASecondaryButton class="gray_button" text="취소" @click="goBack"/>
                    <KSAPrimaryButton text="저장" @click="update"/>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent"/>
    <LectureSurveySearchModal ref="surveySearchModal"/>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/theme";

</style>