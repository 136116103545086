<script setup>
    import { ref, onMounted, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import {useUserLectureStore} from "../../../stores/lecture/userLectureStore";
    import lectureUserService from "../../../services/lecture/lectureUserService";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const userLectureStore = useUserLectureStore()

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);

    onMounted(() => {
        retrieveList();
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1;
    });

    const retrieveList = () => {
        let paramObj = {
            lectGb: "01",
            lectOpenYn: "Y",
            pageNo: currentPage.value,
            size: pageSize,
        }

        loadingStore.loading = true;
        lectureUserService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result)
                    list.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    if(isInitView.value) {
                        userLectureStore.setLectureOnlineList(list.value);
                    }
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3>온라인 강의<span v-if="totalCount>0">({{ $number(totalCount) }})</span></h3>
            </div>
            <div class="lecture-wrap">
                <ul class="lecture-list">
                    <li v-for="lecture in (isInitView ? userLectureStore.lectureOnlineList : list)" @click="router.push({name:'LectureOnlineDetail', params:{id: lecture.lectId}})">
                        <div class="lecture-img">
                            <KSAImage :src="lecture.lectThombDir"/>
                        </div>
                        <div class="lecture-text">
                            <h4>{{ lecture.lectName }}</h4>
                            <div class="lecture-info">
                                <p>
                                    <i>
                                        <b>교육기간</b>&nbsp;
                                        <span v-if="lecture.lectTermGb === '01'">{{ lecture.lectTerm}}일</span>
                                        <span v-else>{{ $yyyymmdd(lecture.lectStartYmd) }} ~ {{ $yyyymmdd(lecture.lectEndYmd) }}</span>
                                    </i>
                                    <i><b>총 {{ lecture.lectureIndexCount }}강</b></i></p>
                            </div>
                            <div class="lecture-content">{{ lecture.lectDesc }}</div>
                        </div>
                    </li>
                </ul>
                <div class="result-none lecture-none" v-if="list.length === 0">
                    검색 결과가 없습니다.
                </div>
            </div>
            <Paginate
                v-if="list.length > 0"
                v-model="currentPage"
                :page-count="totalPages"
                :click-handler="onSelectedPage"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .lecture-list {
        li {
            cursor: pointer;
        }
    }
</style>