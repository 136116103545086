<script setup>
    import axios from 'axios'
    import { ref, onMounted, watch, computed, getCurrentInstance, toRaw, onBeforeUnmount } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import {useUserStore} from "../../../stores/user";
    import {useSasTokenStore} from "../../../../../stores/sasTokenStore";
    import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";
    import {useCertificateStore} from "../../../stores/mypage/certificateStore";
    import _ from "lodash";
    import moment from "moment";
    import LecturePlayerModal from "../../../components/modal/mypage/LecturePlayerModal.vue";
    import lectureUserService from "../../../services/lecture/lectureUserService";
    import lectureHomeworkService from "../../../../admin/services/lecture/lectureHomeworkService";
    import lectureQnaService from "../../../../admin/services/lecture/lectureQnaService";
    import lectureNoticeService from "../../../../admin/services/lecture/lectureNoticeService";
    import KSAVideo from "../../../../../components/ui-components/video/KSAVideo.vue";
    import LectureResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";
    import LectureSurveyModal from "../../../components/modal/mypage/LectureSurveyModal.vue";
    import fileService from "../../../../../services/fileService";
    import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const sasTkStore = useSasTokenStore()
    const lectureStore = useMyPageLectDetailStore()
    const { user, isAuthenticated } = useUserStore()
    const { $pageListBoardNo, $toast } = getCurrentInstance().appContext.config.globalProperties;

    const LECTURE = 'LECTURE';
    const QNA_CREATE = 'QNA_CREATE';
    const QNA_UPDATE = 'QNA_UPDATE';
    const QNA_DETAIL = 'QNA_DETAIL';
    const NOTICE_DETAIL = 'NOTICE_DETAIL';

    const mobileYn = ref(false);
    const resolvePromise = ref(null);

    onBeforeUnmount(()=>{
        localStorage.removeItem('data-lect-cert');
    })

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'MyPageLecture'})
        } else {
            loadingStore.loading = true;
            const p1 = retrieveLectureDetail(id)
            const p2 = retrieveUserLectureAllIndex(id)
            const p3 = retrieveUserLectureStatus(id)
            Promise.all([p1, p2, p3])
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res[0])
                    // console.log(res[1])
                    if(res[0].data.code.toLowerCase() === 'success') {
                        const result = res[0].data.result;
                        lectDetail.value = result;

                        if(result.lectGb === '01') {
                            tabOptions.value.splice(1,1); // TEST 탭 삭제
                        }
                    } else {
                      console.log(res[0].response.data.msg);
                      modalContent.value = [res[0].response.data.msg];
                      const mod = await modal.value.show();
                      if( mod ) {
                        router.go(-1);
                      }
                    }
                    if(res[1].data.code.toLowerCase() === 'success') {
                        const result = res[1].data.result;
                        lectIndexList.value = result;

                        if(lectIndexList.value.length > 0) {
                            const teacherNames = _.uniq(_.map(lectIndexList.value, 'teacherUserName'));
                            if(teacherNames.length > 0) {
                                lectDetail.value.teacherNameText = teacherNames[0];
                                if(teacherNames.length > 1) {
                                    lectDetail.value.teacherNameText += ` 외 ${teacherNames.length-1}명`;
                                }
                            }

                        }
                    }
                    if(res[2].data.code.toLowerCase() === 'success') {
                        const result = res[2].data.result;

                        if(result.surveyYn === '1') {
                            surveyYn = true; // 만족도평가 여부 변경
                        }
                    }
                })
                .catch( async (err) => {
                    loadingStore.loading = false;
                    if (err.response && err.response.data && err.response.data.msg) {
                      console.log(err.response.data.msg);
                      modalContent.value = [err.response.data.msg];
                      const mod = await modal.value.show();
                      if( mod ) {
                        router.go(-1);
                      }
                    } else {
                      console.log(err);
                    }
                })
        }

        // 보안PDF 접속 가능 여부 확인
        // 접속가능IP인지 확인
        checkClientIp(id);
        // checkIp();

        // 모바일여부 확인
        document.querySelector('.container').addEventListener('touchstart', onTouchStart);
        if( window.matchMedia("screen and (max-width: 900px)").matches ) {
            mobileYn.value = true;
        }
    })

    let myIp = '';

    // 보안 PDF 접속 가능 IP 체크
    const checkIp = () => {
      lectureUserService.getClientIp()
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              myIp = res.data.result;
              if( myIp.indexOf(',') > 0  ) { // 경로상 IP 제거
                myIp = myIp.substring(0,myIp.indexOf(','));
              }
              if( myIp.indexOf(':') > 0  ) { // 포트 제거
                myIp = myIp.substring(0,myIp.indexOf(':'));
              }
              if ( allowIpList.indexOf(myIp) < 0 ) { // 허용IP목록에 없다면 불가
                accessOk = false;
              } else { // 허용IP목록에 있다면 허용
                accessOk = true;
              }
              //accessOk = true; // 임시허용
            }
          }).catch( (error) =>  {
        console.log(error);
      });
    }

    // 보안PDF 접속 가능 IP
    const allowIpList = ["121.134.157.2",
      "221.140.5.121",  // 11층 유선
      "218.49.117.228", // 9층 무선
      "218.49.117.238", // 11층 무선
      "121.167.5.85",   // SG 유선
      "121.167.5.76",   // SG 무선
      "221.140.5.66",   // 2023.06.28 추가
      "221.140.10.80",  // 9층 무선 (2023.07.07 추가)
      "221.140.10.100", // 9층 무선 (2023.07.07 추가)
      "210.95.145.164", // 2024.01.09 추가
      "114.206.169.54"  // 2024.05.17 추가
    ];

    const videoPlayDisabled = computed(()=>{
        const { userLectStartYmd, userLectEndYmd } = lectDetail.value;
        const nowYmd = moment().format("YYYYMMDD");
        return !(Number(nowYmd) >= Number(userLectStartYmd) && Number(nowYmd) <= Number(userLectEndYmd));
    })

    const showVideo = ref(false)
    const viewMode = ref(LECTURE); // LECTURE, QNA_CREATE, QNA_DETAIL, NOTICE_DETAIL

    const MAX_SIZE = 200;  // 최대파일사이즈(MB)

    const lectDetail = ref({})
    const lectIndexList = ref([])

    let surveyYn = false;
    let testYn = false;

    const homeworkList = ref([])
    const homeworkInput = ref({
        userId: '',
        lectId: '',
        regDt: '',
        hwId: '',
        attachList: [  // 첨부파일
            {
                attachSeq: 0,
                fileName: "",
                fileSize: "",
                fileDir: "",
                deleteYn: "N"
            }
        ],
    })

    const myPostYn = ref(false)
    const qnaList = ref([])
    const qnaInput = ref({
        title: '',
        content: '',
        secretYn: false,
    })
    const qnaInputError = ref({
        title: '',
        content: '',
    })
    const qnaDetail = ref({})

    const noticeList = ref([])
    const noticeDetail = ref({
        attachList: [],
    })

    const currentPage = ref(1);
    const pageSize = 30;
    const totalPages = ref(1);
    const totalCount = ref(0);

    const tabOptions = ref([
        {label:'학습목차', value: 0},
        {label:'TEST', value: 1},
        {label:'과제', value: 2},
        {label:'Q&A', value: 3},
        {label:'공지사항', value:4},
        {label:'강의소개', value: 5},
    ]);
    const tabIndex = ref(0)

    const searchTypeOptions = [
        {label:' 전체', value: 'all'},
        {label:' 제목', value: 'title'},
        {label:' 내용', value: 'content'},
    ]

    const searchInputs = ref({
        searchType: 'all',
        searchInput: '',
    })

    const modal = ref(null)
    const confirmModal = ref(null)
    const lecturePlayerModal = ref(null)
    const lectureResumeModal = ref(null)
    const lectureSurveyModal = ref(null)
    const modalContent = ref([]);
    const okButtonText = ref("삭제");

    let accessOk = false;

    const retrieveLectureDetail = async (lectId) => {
        const { regDt, userId } = lectureStore.lecture;
        if( !regDt ) {
          modalContent.value = ['잘못된 접근입니다.'];
          const mod = await modal.value.show();
          if( mod ) {
            router.push({name: 'Home'});
          }
        }
        const paramObj = {
            lectId,
            regDt,
            userId,
        }
        return lectureUserService.retrieveMyLectureDetail(paramObj)
    }

    const retrieveUserLectureAllIndex = (id) => {
        return lectureUserService.retrieveUserLectureAllIndex(id, 0);
    }

    const retrieveUserLectureStatus = (lectId) => {
        const { regDt, userId } = lectureStore.lecture;
        return lectureUserService.retrieveUserLectureStatus(userId, lectId, regDt);
    }

    const onSelectTab = (option) => {
        tabIndex.value = option.value;
        currentPage.value = 1;

        if(tabIndex.value === 2) { // 과제
            retrieveLectureHomeworkList();
        } else if(tabIndex.value === 3) { // Q&A
            searchInputs.value = {
                searchType: 'all',
                searchInput: '',
            };
            retrieveLectureQnAList()
        } else if(tabIndex.value === 4) { // 공지사항
            retrieveLectureNoticeList();
        }
    }

    const onClickRequestCert = () => {
        // todo 1.만족도 평가 완료 여부 체크
        if(!surveyYn) {
            modalContent.value = ["만족도 평가를 제출해야 수료증을 발급할 수 있습니다."];
            modal.value.show();
            return false;
        }
        // todo 2.수료 여부 체크
        if(lectDetail.value.finYn !== 'Y') {
            modalContent.value = ["해당 강의를 수료하지 못했습니다."];
            modal.value.show();
            return false;
        }

        const certStore = useCertificateStore();
        certStore.certificate.lectDetail = _.cloneDeep(toRaw(lectDetail.value));
        certStore.certificate.lectIndexes = _.cloneDeep(toRaw(lectIndexList.value));
        certStore.certificate.userDetail = _.cloneDeep(toRaw(user.userInfo));
        // console.log(certStore.certificate);
        window.open('/mypage/lecture/certificate', '_blank');
    }


    const onClickSurvey = async () => {

        // 과정에 등록된 만족도 평가가 있는지 체크
        if ( !lectDetail.value.survey ) {
            modalContent.value = ["강의에 등록된 만족도가 없습니다.", "관리자에게 문의하세요."];
            modal.value.show();
            return false;
        }

        // 만족도 평가 완료 여부 체크
        if(surveyYn) {
            modalContent.value = ["이미 제출하였습니다."];
            modal.value.show();
            return false;
        }

        const { userId, lectId, regDt } = lectureStore.lecture;

        // userId, lectId, regDt가 null이거나 빈스트링인지 체크
        if ( !userId || !lectId || !regDt || (userId === "") || (lectId === "") || (regDt === "") ) {
            modalContent.value = ["오류가 발생하였습니다.", "로그아웃 후 다시 로그인하여 주시기 바랍니다.", "재로그인 후에도 동일한 오류가 발생한다면 관리자에게 문의하세요."];
            modal.value.show();
            return false;
        }

        // 만족도 평가 팝업
        const paramObj = {
            userId,
            lectId,
            regDt,
        }

        lectureSurveyModal.value.init(userId, lectId, regDt, lectDetail.value.survey.surveyId)
        const result = await lectureSurveyModal.value.show();
        console.log("result : " + result);

        // 페이지 새로고침
        if ( result ) {
            //router.replace( { name: 'MyPageLectureDetail', params: {id: lectDetail.value.lectId} } );
            router.go(0);
        }
    }

    const onClickDeleteLecture = async () => {
        modalContent.value = ["목록에서 강의를 삭제할까요?"];
        okButtonText.value = "삭제";
        const ok = await confirmModal.value.show();
        if(ok) {
            deleteLecture();
        }
    }

    const deleteLecture = () => {
        loadingStore.loading = true;
        lectureUserService.deleteUserLectureByLectId(lectDetail.value.lectId, lectDetail.value.regDt)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result)
                    $toast('', '강의를 삭제하였습니다.');
                    router.push({name: 'MyPageLecture'})
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickMyResume = async () => {
        // 이력서 팝업 오픈
        lectureResumeModal.value.retrieveUserLectureRecordDetail(lectDetail.value.userId, lectDetail.value.lectId, lectDetail.value.regDt);
        const result = await lectureResumeModal.value.show();
        if(result){
        }
    }

    // s : 과제

    const retrieveLectureHomeworkList = () => {
        loadingStore.loading = true;
        lectureHomeworkService.retrieveHomeworkByLectId(lectDetail.value.lectId)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    homeworkList.value = result;
                } else {
                }
            }).catch( (error) =>  {
            console.log(error);
            loadingStore.loading = false;
        });
    }

    const submitHomework = async (hwId) => {
        homeworkInput.value.userId = lectDetail.value.userId;
        homeworkInput.value.lectId = lectDetail.value.lectId;
        homeworkInput.value.regDt = lectDetail.value.regDt;
        homeworkInput.value.hwId = hwId;

        modalContent.value = ["제출한 과제는 삭제하거나 수정할 수 없습니다.", "제출할까요?"];
        okButtonText.value = "제출";
        const ok = await confirmModal.value.show()

        if ( ok ) {
            uploadFile()
                .then( async (res) => {
                    // 제출내역 저장
                    loadingStore.loading = true;
                    lectureHomeworkService.createUserHomework(homeworkInput.value)
                        .then( async (res) => {
                            loadingStore.loading = false;
                            if(res.data.code.toLowerCase() === 'success') {
                                retrieveLectureHomeworkList();
                            } else {
                                $toast('', '과제 등록에 실패하였습니다.');
                            }
                        }).catch( (error) =>  {
                        console.log(error);
                        loadingStore.loading = false;
                    });
                    console.log("uploadFile().then() finish");
                }).catch( (error) =>  {
                    console.log(error);
                    loadingStore.loading = false;
            });
        }
    }

    const uploadFile = () => {
        return new Promise(function(resolve, reject) {
            const fileInput = document.createElement('input');
            fileInput.type= 'file';
            fileInput.click();

            fileInput.addEventListener('change', function(event){
                // console.log(event.target.files);
                const file = event.target.files[0];
                // console.log(file);
                if(file.size > (MAX_SIZE*1024*1024)) {
                    modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                    alertModal.value.show();
                    resolve(false);
                } else {
                    let paramObj = {
                        container: 'public',
                        folder: 'cs_notice',
                        file,
                    }

                    fileService.uploadFile(paramObj)
                        .then((res) => {
                            // console.log(res);
                            if(res.data.code.toLowerCase() === 'success') {
                                const result = res.data.result;
                                // console.log(result);
                                homeworkInput.value.attachList[0].fileName = result.originalFilename;
                                homeworkInput.value.attachList[0].fileSize = result.fileSize;
                                homeworkInput.value.attachList[0].fileDir = result.blobUrl;
                            }
                            resolve(true);
                            console.log("finish upload");
                        })
                        .catch( (error) =>  {
                            reject( new Error("File Upload Failed.") );
                            console.log(error);
                        });
                }
            });
        });
    }

    // e : 과제

    // s : QNA

    watch(myPostYn, ()=>{
        currentPage.value = 1;
        retrieveLectureQnAList();
    });

    const retrieveLectureQnAList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: pageSize,
            searchType: searchInputs.value.searchType,
            searchInput: searchInputs.value.searchInput,
            myPostGb: myPostYn.value ? 'Y' : 'N',
        }
        loadingStore.loading = true;
        lectureQnaService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    qnaList.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const retrieveQnADetail = (id) => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: id,
        }

        loadingStore.loading = true;
        lectureQnaService.retrieveDetail(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectSearchType = (option) => {
        searchInputs.value.searchType = option.value;
    }

    const onClickSearchInput = (value) => {
        searchInputs.value.searchInput = value;
        currentPage.value = 1;
        retrieveLectureQnAList();
    }

    const createQnA = () => {
        const { title, content, secretYn } = qnaInput.value;
        let isInvalid = false;

        qnaInputError.value = {
            title: '',
            content: '',
        }

        if(!title) {
            qnaInputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            qnaInputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                title,
                content,
                secretYn: secretYn ? 'Y' : 'N',
            }
            loadingStore.loading = true;
            lectureQnaService.createQNA(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        retrieveLectureQnAList();
                        setViewMode(LECTURE)
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const updateQnA = () => {
        const { title, content, secretYn } = qnaInput.value;
        let isInvalid = false;

        qnaInputError.value = {
            title: '',
            content: '',
        }

        if(!title) {
            qnaInputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            qnaInputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                boardId: qnaDetail.value.boardId,
                title,
                content,
                secretYn: secretYn ? 'Y' : 'N',
            }
            loadingStore.loading = true;
            lectureQnaService.updateQNA(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        qnaDetail.value = result;
                        // retrieveLectureQnAList();
                        setViewMode(QNA_DETAIL, qnaDetail.value.boardId)
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const deleteQnA = async () => {
        modalContent.value = ["문의글을 삭제할까요?"];
        okButtonText.value = "삭제";
        const ok = await confirmModal.value.show()
        if(ok){
            _deleteQnA()
        }
    }

    function _deleteQnA() {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: qnaDetail.value.boardId
        };

        loadingStore.loading = true;
        lectureQnaService.deleteQNA(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    retrieveLectureQnAList();
                    setViewMode(LECTURE);
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    // e : QNA

    // s : 공지사항

    const retrieveLectureNoticeList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: pageSize,
        }
        loadingStore.loading = true;
        lectureNoticeService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    noticeList.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const retrieveNoticeDetail = (id) => {
        noticeDetail.value = {
            title: '',
            content: '',
            attachList: [],
        };

        loadingStore.loading = true;
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: id,
        }
        lectureNoticeService.retrieveDetail(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    noticeDetail.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    // e : 공지사항

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;

        if(tabIndex.value === 3) { // Q&A
            retrieveLectureQnAList()
        } else if(tabIndex.value === 4) { // 공지사항
            retrieveLectureNoticeList();
        }
    }

    const setViewMode = (viewName, boardId) => {
        if(viewName === QNA_CREATE) {
            const { lectStateGb } = lectDetail.value;
            if(lectStateGb === '01' || lectStateGb === '05') {
                modalContent.value = ["교육기간 시작 전에는 Q&A를 작성할 수 없습니다."];
                modal.value.show();
                return false;
            } else if(lectStateGb === '03') {
                modalContent.value = ["교육기간이 종료되어 Q&A를 작성할 수 없습니다."];
                modal.value.show();
                return false;
            }
        }

        viewMode.value = viewName;
        if(viewName === QNA_CREATE || viewName === QNA_UPDATE) {
            qnaInput.value = {
                title: viewName === QNA_UPDATE ? qnaDetail.value.title : '',
                content: viewName === QNA_UPDATE ? qnaDetail.value.content : '',
                secretYn: viewName === QNA_UPDATE ? (qnaDetail.value.secretYn === 'Y') : false,
            };
            qnaInputError.value = {
                title: '',
                content: '',
            };

            if(viewName === QNA_CREATE) {
                fileService.generateAccountSas();
            } else if(viewName === QNA_UPDATE) {
                sasTkStore.sasToken = qnaDetail.value.sasToken;
            }

        } else if(viewName === 'QNA_DETAIL') {
            retrieveQnADetail(boardId)
        } else if(viewName === 'NOTICE_DETAIL') {
            retrieveNoticeDetail(boardId)
        } else {
            if(tabIndex.value === 3) { // Q&A
                retrieveLectureQnAList()
            } else if(tabIndex.value === 4) { // 공지사항
                retrieveLectureNoticeList()
            }
        }
    }

    const onClickVideoPlay = (lectIndex) => {
        let paramObj = {
            uploadFileKey: lectIndex.uploadFileKey,
            lectId: lectDetail.value.lectId,
            lectIdx: lectIndex.lecIdx,
            regDt: lectDetail.value.regDt,
        }
        lecturePlayerModal.value.show(lectDetail.value, paramObj, false);
    }

    const startVideoPreview = () => {
        if(lectDetail.value.oneMovDir) {
            showVideo.value = true;
        }
    }

    const onClickViewer = (attach) => {
        if ( accessOk ) {
            if( !mobileYn.value ) {
                sessionStorage.setItem('pdfviewer', JSON.stringify({
                    keyName: attach.fileDir,
                    lectId: lectDetail.value.lectId,
                    pageCount: attach.pageCount,
                }));
                window.open('/mypage/lecture/viewer', '_blank');
            } else {
                modalContent.value = ["PC에서만 보안PDF를 확인할 수 있습니다."];
                modal.value.show();
                return false;
            }
        } else {
            const content = "보안PDF 조회가 허가된 IP가 아닙니다.";
            modalContent.value = [content];
            modal.value.show();
            return false;
        }
    }

    const onTouchStart = () => {
        mobileYn.value = true;
    }

    // 보안 PDF 접속 가능 IP 인지 확인
    const checkClientIp = (lectId) => {
      lectureUserService.checkClientIp(lectId)
          .then( (res) => {
            if(res.data.code.toLowerCase() === 'success') {
              accessOk = res.data.result;
            }
          }).catch( (error) =>  {
        console.log(error);
      });
    }

</script>ㅣ

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <!-- S : 강의 상세-->
                <div class="content">
                    <div class="lecture-wrap">
                        <div class="sub-title grid space-between">
                            <h3><span class="badge">{{ lectDetail.lectGb === '01' ? '온라인' : '오프라인' }} 강의</span>{{ lectDetail.lectName }}</h3>
                            <div class="btn-wrap">
                                <KSASecondaryButton v-if="lectDetail.lectStateGb === '03' || lectDetail.finYn === 'Y'" class="gray_button" text="수료증 발급" @click="onClickRequestCert" />
                                <KSASecondaryButton v-if="lectDetail.lectStateGb === '03' || lectDetail.finYn === 'Y'" class="gray_button ml-xs" text="만족도 평가" @click="onClickSurvey" />
                                <KSASecondaryButton v-if="lectDetail.lectStateGb === '05' && (lectDetail.evalStateGb === '02' || lectDetail.evalStateGb === '04' || lectDetail.evalStateGb === '06')" class="red_button ml-xs" text="삭제" @click="onClickDeleteLecture" />
                            </div>
                        </div>
                        <div class="lecture-summary" v-show="viewMode === LECTURE">
                            <div class="left-area">
                                <div v-show="!showVideo" class="img" @click="startVideoPreview">
                                    <KSAImage :src="lectDetail.lectThombDir"/>
                                    <button class="play-btn" v-if="lectDetail.oneMovDir">
                                        <span></span>
                                    </button>
                                </div>
                                <div v-if="showVideo" class="video">
                                    <KSAVideo :src="lectDetail.oneMovDir" :poster="lectDetail.lectThombDir"/>
                                </div>
                            </div>
                            <div class="right-area" v-if="lectDetail.lectGb === '01'">
                                <p>
                                    <span class="badge" :class="{'state1': lectDetail.lectStateGb === '05', 'state2': lectDetail.lectStateGb === '01', 'state3': lectDetail.lectStateGb === '02', 'state4': (lectDetail.lectStateGb === '03' || lectDetail.lectStateGb === '04')}">{{ $gbNameKR('LECT_STATE_GB', lectDetail.lectStateGb) }}</span>
                                </p>
                                <p>
                                    <span>교육 기간</span>
                                    <span v-if="lectDetail.userLectStartYmd && lectDetail.userLectEndYmd">{{ $yyyymmdd(lectDetail.userLectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.userLectEndYmd) }}</span>
                                    <span v-else>-</span>
                                </p>
                                <p>
                                    <span>진도율</span>
                                    <span>{{ lectDetail.progRate || 0 }}%</span>
                                </p>
                                <p>
                                    <span>총 시간</span>
                                    <span>{{ lectDetail.lectureIndexMovPlayTimeSumString || '-' }}</span>
                                </p>
                                <p>
                                    <span>강의 수</span>
                                    <span>총 {{ lectDetail.lectureIndexCount }}차시</span>
                                </p>
                                <p>
                                    <span>강사 명</span>
                                    <span>{{ lectDetail.teacherNameText }}</span>
                                </p>
                                <p>
                                    <span>수료 여부</span>
                                    <span class="point">{{ lectDetail.finYn === 'Y' ? '수료'
                                                         : lectDetail.finYn === 'N' ? '미수료'
                                                         : lectDetail.finYn === 'D' ? '수강중'
                                                         : '-' }}
                                        <small v-if="lectDetail.finCriteriaGb === '02'">* 차시 전체를 학습 완료 시 진도율에 반영됩니다.<br/>(재생 중 강의 종료 시 진도율에 반영되지 않으며, 수강 내역이 저장되지 않습니다.)</small>
                                    </span>
                                </p>
<!--                                <p>-->
<!--                                    <span>제한 인원</span>-->
<!--                                    <span v-if="lectDetail.limitMemYn === 'Y'">{{ lectDetail.limitMemNum }}명</span>-->
<!--                                    <span v-else>없음</span>-->
<!--                                </p>-->
<!--                                <p>-->
<!--                                    <span>수강중 인원</span>-->
<!--                                    <span>{{ lectDetail.userLectureSelCount }}명</span>-->
<!--                                </p>-->
                                <p v-if="lectDetail.note">
                                    <small class="mt-sm">*{{ lectDetail.note }}</small>
                                </p>
                            </div>
                            <div class="right-area" v-if="lectDetail.lectGb === '02'">
                                <KSASecondaryButton class="gray_button" text="제출한 이력서 보기" @click="onClickMyResume" />
                                <p>
                                    <span class="badge" :class="{'state1': lectDetail.lectStateGb === '05', 'state2': lectDetail.lectStateGb === '01', 'state3': lectDetail.lectStateGb === '02', 'state4': (lectDetail.lectStateGb === '03' || lectDetail.lectStateGb === '04')}">{{ $gbNameKR('LECT_STATE_GB', lectDetail.lectStateGb) }}</span>
                                    <span v-if="lectDetail.lectStateGb === '05'">{{ $gbNameKR('EVAL_STATE_GB', lectDetail.evalStateGb) }}</span> <!-- lectStateGb 05(신청완료)-->
                                </p>
                                <p>
                                    <span>교육 기간</span>
                                    <span v-if="lectDetail.lectTermGb === '01'">신청일로부터 {{ lectDetail.lectTerm }}일</span>
                                    <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                                </p>
                                <p>
                                    <span>면접 기간</span>
                                    <span v-if="lectDetail.intvTermGb === '01'">상시</span>
                                    <span v-else>{{ $yyyymmdd(lectDetail.intvStartYmd) }} ~ {{ $yyyymmdd(lectDetail.intvEndYmd) }}</span>
                                </p>
                                <p>
                                    <span>교육 시간</span>
                                    <span v-if="lectDetail.lectHour || lectDetail.lectMin">
                                        {{ lectDetail.lectHour ? `${lectDetail.lectHour}시간` : '' }}{{ lectDetail.lectMin ? ` ${lectDetail.lectMin}분` : '' }}
                                    </span>
                                    <span v-else>-</span>
                                </p>
                                <p>
                                    <span>교육 장소</span>
                                    <span>{{ lectDetail.lectPlace }}</span>
                                </p>
                                <p>
                                    <span>강사 명</span>
                                    <span>{{ lectDetail.teacherNameText }}</span>
                                </p>
                                <p>
                                    <span>수료 여부</span>
                                    <span class="point">{{ lectDetail.finYn === 'Y' ? '수료'
                                                         : lectDetail.finYn === 'N' ? '미수료'
                                                         : lectDetail.finYn === 'D' ? '수강중'
                                                         : '-' }}</span>
                                </p>
<!--                                <p v-if="lectDetail.regMemOpenYn === 'Y'">-->
<!--                                    <span>신청 인원</span>-->
<!--                                    <span>{{ lectDetail.userLectureCount }}명</span>-->
<!--                                </p>-->
<!--                                <p v-if="lectDetail.selMemOpenYn === 'Y'">-->
<!--                                    <span>선발 인원</span>-->
<!--                                    <span>{{ lectDetail.userLectureSelCount }}명</span>-->
<!--                                </p>-->
                                <p v-if="lectDetail.note">
                                    <small>*{{ lectDetail.note }}</small>
                                </p>
                            </div>
                        </div>
                        <div class="lecture-detail" v-show="viewMode === LECTURE">
                            <KSATab class="tab-style_border" :options="tabOptions" @change="onSelectTab"/>
                            <!-- s : 학습 목차(온라인) -->
                            <div class="table-form" v-if="tabIndex === 0 && lectDetail.lectGb === '01'">
                                <table class="horizontal">
                                    <tr>
                                        <th>차시</th>
                                        <th>차시명</th>
                                        <th>시간</th>
                                        <th>학습 상태</th>
                                        <th>수강하기</th>
                                    </tr>
                                    <tr v-for="lectIndex in lectIndexList">
                                        <td>
                                            {{ lectIndex.idxNum }}차시
                                        </td>
                                        <td class="text-left">{{ lectIndex.idxName }}</td>
                                        <td>
                                            <span v-if="lectIndex.movPlayHm">{{ $movPlayHm(lectIndex.movPlayHm) }}</span>
                                            <span v-else>{{ lectIndex.uploadFileKey ? '인코딩 중' : '-' }}</span>
                                        </td>
                                        <td>
                                            <span v-if="!lectIndex.realPlaytime">학습 전</span>
                                            <span v-else-if="lectIndex.idxFinishYn !== 'Y'">학습중</span>
                                            <span v-else-if="lectIndex.playtimePercent >= 100 || lectIndex.idxFinishYn === 'Y'">학습 완료</span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <KSASecondaryButton text="수강하기" @click="onClickVideoPlay(lectIndex)" :disabled="videoPlayDisabled || !(lectIndex.uploadFileKey && lectIndex.movPlayHm)" />
                                        </td>
                                    </tr>
                                    <tr v-if="lectIndexList.length === 0">
                                        <td colspan="5">등록된 차시가 없습니다.</td>
                                    </tr>
                                </table>
                            </div>
                            <!-- e : 학습 목차(온라인) -->
                            <!-- s : 학습 목차(오프라인) -->
                            <div class="table-form" v-if="tabIndex === 0 && lectDetail.lectGb === '02'">
                                <table class="horizontal">
                                    <tr>
                                        <th>주차</th>
                                        <th>일시</th>
                                        <th>요일</th>
                                        <th>시간</th>
                                        <th>강사명</th>
                                        <th>실습 여부</th>
                                        <th>교육명</th>
                                        <th>필요 기자재</th>
                                    </tr>
                                    <tr v-for="(lectIndex, idx) in lectIndexList">
                                        <td>{{ lectIndex.idxNum }}차시</td>
                                        <td>
                                            <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                            <span v-else>{{ $yyyymmdd(lectIndex.idxYmd) }}</span>
                                        </td>
                                        <td>
                                            <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                            <span v-else>{{ $dateToWeekDay(lectIndex.idxYmd) }}</span>
                                        </td>
                                        <td>{{ lectIndex.idxHms }}시간</td>
                                        <td>
                                            <span v-if="!lectIndex.teacherUserName">-</span>
                                            <span v-else>{{ lectIndex.teacherUserName }}</span>
                                        </td>
                                        <td>{{ lectIndex.practiceYn === 'Y' ? 'O' : 'X' }}</td>
                                        <td class="text-left">{{ lectIndex.idxName }}</td>
                                        <td>{{ lectIndex.supplies }}</td>
                                    </tr>
                                    <tr v-if="lectIndexList.length === 0">
                                        <td colspan="8">등록된 차시가 없습니다.</td>
                                    </tr>
                                </table>
                            </div>
                            <!-- e : 학습 목차(오프라인) -->

                            <!-- s : TEST -->
                            <div class="table-form" v-if="tabIndex === 1">
                                <div class="result-none lecture-none">
                                    진행중인 테스트가 없습니다.
                                </div>
                            </div>
                            <!-- e : TEST -->

                            <!-- s : 과제 -->
                            <div class="table-form" v-if="tabIndex === 2">
                                <div class="table-form">
                                    <table class="horizontal">
                                        <tr>
                                            <th>제목</th>
                                            <th>파일</th>
                                            <th>제출기한</th>
                                            <th>제출</th>
                                            <th>상태</th>
                                            <th>점수</th>
                                        </tr>
                                        <tr v-for="homework in homeworkList">
                                            <td>{{ homework.title }}</td>
                                            <td>
                                                <KSATextButton v-if="homework.hwFileName" class="" :text=homework.hwFileName @click="$download(homework.hwFileDir)" :title=homework.hwFileName />
                                            </td>
                                            <td>{{ $yyyymmdd(lectDetail.userLectEndYmd) }}까지</td>
                                            <td>
                                                <KSATextButton v-if="homework.userHwFileName" class="" :text=homework.userHwFileName @click="$download(homework.userHwFileDir)" :title=homework.userHwFileName />
                                                <KSATextButton v-else class="" text="제출하기" @click="submitHomework(homework.hwId)" />
                                            </td>
                                            <td>
                                                <span v-if="homework.markId">채점완료</span>
                                                <span v-else-if="homework.submitId">제출완료</span>
                                                <span v-else>제출 전</span>
                                            </td>
                                            <td>
                                                <span v-if="homework.score">{{ homework.score }}점</span>
                                                <span v-else>-</span>
                                            </td>
                                        </tr>
                                        <tr v-if="homeworkList.length === 0">
                                            <td colspan="6">등록한 과제가 없습니다.</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <!-- e : 과제 -->

                            <!-- s : Q&A -->
                            <div v-else-if="tabIndex === 3">
                                <div class="list-top">
                                    <div style="display: flex">
                                        <p>총 게시물 <span>{{ $number(totalCount)}}</span>건</p>
                                        <KSACheckBox label="내가 작성한 Q&amp;A 보기" v-model="myPostYn"  />
                                    </div>
                                    <div class="search-box">
                                        <KSASelect :options="searchTypeOptions" class="search-select" @selectOption="onSelectSearchType"/>
                                        <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onClickSearchInput" />
                                    </div>
                                </div>
                                <div class="table-form">
                                    <table class="horizontal">
                                        <tr>
                                            <th>번호</th>
                                            <th>제목</th>
                                            <th>작성자</th>
                                            <th>작성일</th>
                                            <th>상태</th>
                                        </tr>
                                        <tr v-for="board in qnaList" @click="setViewMode(QNA_DETAIL, board.boardId)">
                                            <td>{{ board.boardNo }}</td>
                                            <td class="text-left"><a href="javascript:void(0);">{{ board.title }}</a></td>
                                            <td>{{ board.createUserName }}</td>
                                            <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                                            <td :class="{'wait': !board.answer, 'complete': board.answer}">답변 {{ board.answer ? '완료' : '대기' }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="result-none lecture-none" v-if="qnaList.length === 0">
                                    등록된 Q&A가 없습니다.
                                </div>
                                <div class="btn-wrap text-right mb-xs">
                                    <KSASecondaryButton class="gray_button" text="글 작성" @click="setViewMode(QNA_CREATE)" />
                                </div>
                                <Paginate
                                    v-if="qnaList.length > 0"
                                    v-model="currentPage"
                                    :page-count="totalPages"
                                    :click-handler="onSelectPage"
                                />
                            </div>
                            <!-- e : Q&A -->

                            <!-- s : 공지사항 -->
                            <div v-else-if="tabIndex === 4">
                                <div class="list-top">
                                    <p>총 게시물 <span>{{ $number(totalCount)}}</span>건</p>
                                </div>
                                <div class="table-form">
                                    <table class="horizontal">
                                        <tr>
                                            <th>번호</th>
                                            <th>제목</th>
                                            <th>작성자</th>
                                            <th>작성일</th>
                                            <th>조회수</th>
                                        </tr>
                                        <tr v-for="board in noticeList" @click="setViewMode(NOTICE_DETAIL, board.boardId)">
                                            <td>{{ board.boardNo }}</td>
                                            <td class="text-left"><a href="javascript:void(0);">{{ board.title }}<img v-if="board.attachList.length > 0 && board.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></a></td>
                                            <td>{{ board.createUserName }}</td>
                                            <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                                            <td>{{ $number(board.hitCount) }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="result-none lecture-none" v-if="noticeList.length === 0">
                                    등록된 공지사항이 없습니다.
                                </div>
                                <Paginate
                                    v-if="noticeList.length > 0"
                                    v-model="currentPage"
                                    :page-count="totalPages"
                                    :click-handler="onSelectPage"
                                />
                            </div>
                            <!-- e : 공지사항 -->

                            <!-- s : 강의소개 -->
                            <div class="table-form" v-if="tabIndex === 5">
                                <div class="editor-wrap" v-html="lectDetail.lectIntro"></div>
                            </div>
                            <!-- e : 강의소개 -->
                        </div>
                        <div class="lecture-detail" v-show="viewMode !== LECTURE">
                            <!-- S : QNA 생성 -->
                            <div v-if="viewMode === QNA_CREATE">
                                <div class="board-detail">
                                    <div class="table-form">
                                        <table class="vertical">
                                            <colgroup>
                                                <col width="110px" />
                                                <col />
                                            </colgroup>
                                            <tr>
                                                <th>제목</th>
                                                <td>
                                                    <KSAInput class="text-box mb-xs" v-model="qnaInput.title" :msg="qnaInputError.title" placeholder="제목을 입력하세요."/>
                                                    <!--                                    <KSACheckBox label="비밀글로 설정합니다." v-model="qnaInput.secretYn" />-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <KSACKEditor v-model="qnaInput.content" container="private"/>
                                                    <div>
                                                        <small style="color:#E71414;">{{ qnaInputError.content }}</small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="취소" @click="setViewMode(LECTURE)" />
                                    <KSAPrimaryButton text="등록" @click="createQnA" :disabled="!qnaInput.title || !qnaInput.content" />
                                </div>
                            </div>
                            <!-- E : QNA 생성 -->
                            <!-- S : QNA 수정 -->
                            <div v-if="viewMode === QNA_UPDATE">
                                <div class="board-detail">
                                    <div class="table-form">
                                        <table class="vertical">
                                            <colgroup>
                                                <col width="110px" />
                                                <col />
                                            </colgroup>
                                            <tr>
                                                <th>제목</th>
                                                <td>
                                                    <KSAInput class="text-box mb-xs" v-model="qnaInput.title" :msg="qnaInputError.title" placeholder="제목을 입력하세요."/>
                                                    <!--                                    <KSACheckBox label="비밀글로 설정합니다." v-model="qnaInput.secretYn" />-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <KSACKEditor v-model="qnaInput.content" container="private"/>
                                                    <div>
                                                        <small style="color:#E71414;">{{ qnaInputError.content }}</small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="취소" @click="setViewMode(QNA_DETAIL, qnaDetail.boardId)" />
                                    <KSAPrimaryButton text="저장" @click="updateQnA" :disabled="!qnaInput.title || !qnaInput.content" />
                                </div>
                            </div>
                            <!-- E : QNA 수정 -->
                            <!-- S : QNA 상세 -->
                            <div v-if="viewMode === QNA_DETAIL && qnaDetail.boardId">
                                <div class="board-detail">
                                    <div class="header" style="position: relative">
                                        <div class="board-title">
                                            <span :class="{'complete': qnaDetail.answer}">답변 {{ qnaDetail.answer ? '완료' : '대기' }}</span>
                                            <p>{{ qnaDetail.title }} <img v-if="qnaDetail.secretYn === 'Y'" src="/src/assets/images/icon-lock.png" /></p>
                                        </div>
                                        <span>작성일 <i>{{ $yyyymmddOrHHmm(qnaDetail.createDate) }}</i></span>
                                        <span>작성자 <i>{{ qnaDetail.createUserName || '작성자' }}<span v-if="qnaDetail.createUserLoginId">({{ qnaDetail.createUserLoginId }})</span></i></span>
                                        <span>조회수 <i>{{ $number(qnaDetail.hitCount) }}</i></span>
                                    </div>
                                    <div class="body">
                                        <div class="text" v-html="qnaDetail.content"></div>
                                        <div class="answer" v-if="qnaDetail.answer">
                                            <span>답변</span>
                                            <p>{{ qnaDetail.answererUserName }}
                                                <span v-if="qnaDetail.answererUserLoginId">({{ qnaDetail.answererUserLoginId }})</span>
                                            </p>
                                            <div class="text">
                                                <div v-html="qnaDetail.answer"></div>
                                                <p>{{ $yyyymmdd(qnaDetail.answerDate) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="목록" @click="setViewMode(LECTURE)" />
                                    <div v-if="qnaDetail.createUserLoginId && user.userLoginId === qnaDetail.createUserLoginId">
                                        <KSASecondaryButton text="수정" @click="setViewMode(QNA_UPDATE)" />
                                        <KSASecondaryButton class="red_button" text="삭제" @click="deleteQnA" />
                                    </div>
                                </div>
                            </div>
                            <!-- E : QNA 상세 -->

                            <!-- S : 공지사항 상세-->
                            <div v-if="viewMode === NOTICE_DETAIL && noticeDetail.boardId">
                                <div class="board-detail">
                                    <div class="header">
                                        <div class="board-title">
                                            <p>{{ noticeDetail.title }}<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                                        </div>
                                        <span>작성일<i>{{ $yyyymmddOrHHmm(noticeDetail.createDate) }}</i></span>
                                        <span>작성자 <i>{{ noticeDetail.createUserName }}<span v-if="noticeDetail.createUserLoginId">({{ noticeDetail.createUserLoginId}})</span></i></span>
                                        <span>조회수 <i>{{ $number(noticeDetail.hitCount)}}</i></span>
                                    </div>
                                    <div class="body">
                                        <div class="text" v-html="noticeDetail.content"></div>
                                    </div>
                                    <div class="control">
                                        <dl class="download" v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName">
                                            <dt>첨부</dt>
                                            <dd>
                                                <div v-for="attach in noticeDetail.attachList">
                                                    {{ attach.fileName }}
                                                    <KSATextButton v-if="attach.fileDir" class="ml-xs" text="다운로드" @click="$downloadForPrivate(attach)" />
                                                </div>
                                            </dd>
                                        </dl>
                                        <dl class="download" v-if="noticeDetail.securePdfList.length > 0 && noticeDetail.securePdfList[0].fileName">
                                            <dt>보안 PDF</dt>
                                            <dd>
                                                <div v-for="attach in noticeDetail.securePdfList">
                                                    {{ attach.fileName }}
                                                    <KSATextButton v-if="attach.fileDir" class="ml-xs" text="뷰어로 보기" @click="onClickViewer(attach)" />
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="목록" @click="setViewMode(LECTURE)" />
                                </div>
                            </div>
                            <!-- E : 공지사항 상세-->
                        </div>
                    </div>
                </div>
                <!-- E : 강의 상세-->
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" />
    <LecturePlayerModal ref="lecturePlayerModal"/>
    <LectureResumeModal ref="lectureResumeModal" :showButtons="false"/>
    <LectureSurveyModal ref="lectureSurveyModal" />
</template>

<style lang="scss" scoped>
    .board-detail {
        .header {
            border-top: 0;
        }
        .table-form {
            table {
                border-top: 0;
            }
        }
    }
</style>