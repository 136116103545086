<script setup >
    import { ref, computed, defineEmits, defineExpose, onMounted, toRefs } from "vue";
    import _ from 'lodash'

    const props = defineProps({
        className: { type: String},
        modelValue: { type: String },
        options: { type: Array },
        name: { type: String },
        disabled: { type: Boolean, default: false, }
    });


    onMounted(()=>{
        emit('update:modelValue', checkedValues.value);
    })

    const { className, modelValue, options, name, disabled} = toRefs(props)

    const isAllChked = ref(true);
    const checkedValues = ref(_.map(options.value, 'value'));

    const itemRefs= ref([])

    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const onChangeAllChked = () => {
        if(isAllChked.value) {
            checkedValues.value = _.map(options.value, 'value');
        } else {
            checkedValues.value = [];
        }
        emit('update:modelValue', checkedValues.value);
    }

    const onClick = () => {
        isAllChked.value = checkedValues.value.length === options.value.length;
        emit('update:modelValue', checkedValues.value);
    }

    const initOptions = (modelUpdateYn) => {
        checkedValues.value = _.map(options.value, 'value');
        isAllChked.value = true;
        if(modelUpdateYn === 'Y') {
            emit('update:modelValue', checkedValues.value);
        }
    }

    const initOptionsWithValues = (values) => {
        checkedValues.value = values; // ['a','b','c', ...]
        isAllChked.value = checkedValues.value.length === options.value.length;
        // console.log(values);
        // console.log(isAllChked.value);
        setTimeout(()=>{
            for(let i=0; i<itemRefs.value.length; i++) {
                // console.log(itemRefs.value[i]);
                itemRefs.value[i].init(values.indexOf(options.value[i].value) > -1);
            }
        }, 500)
    }

    defineExpose({
        initOptions,
        initOptionsWithValues,
    })
</script>

<template>
    <KSACheckBox
        :class="className"
        v-model="isAllChked"
        :name="name"
        label="전체"
        value="all"
        :disabled="disabled"
        @change="onChangeAllChked"/>
    <KSACheckBox
        ref="itemRefs"
        :class="className"
        v-for="option in options"
        v-model="checkedValues"
        :name="name"
        :label="option.label"
        :value="option.value"
        :disabled="disabled"
        @change="onClick"/>
</template>

<style lang="scss" scoped>
</style>