<script setup>
    import {ref, defineExpose } from 'vue'

    const record = ref({})
    const hidden = ref(0)

    const setRecord = (value) => {
        record.value = value;
        // console.log(record);
    }

    const setHidden  = (value) => {
        hidden.value = value;
    }

    defineExpose({
        setRecord,
        setHidden,
    })
</script>

<template>
    <div class="resume-wrap">
        <div class="self-info">
            <h1>{{ record.userName }}</h1>
            <p><span>{{ $phoneNum(record.phoneNum) }}</span><span>{{ record.email }}</span></p>
            <p v-show="hidden > 0 && record.hiddenYn > 0" class="aline-notice"><span><img class="icon-alert-image" src="/src/assets/images/icon-alert.svg"> 핸드폰 번호 및 이메일 정보는 '매칭완료' 후 확인이 가능합니다.</span></p>
        </div>
        <!-- s : 기본정보 -->
        <section class="basic">
            <dl v-if="record.interests">
                <dt>관심 카테고리</dt>
                <dd>{{ record.interests }}</dd>
            </dl>
            <dl v-if="record.desiredJob">
                <dt>희망 직무</dt>
                <dd>{{ record.desiredJob }}</dd>
            </dl>
            <dl v-if="record.desiredSalary">
                <dt>희망 연봉</dt>
                <dd>
                    <span>{{ $number(record.desiredSalary) }}만원 이상</span>
                </dd>
            </dl>
            <div v-if="record.introOpenYn === 'Y' && (record.introAline || record.selfIntro)">
                <h2>{{ record.introAline }}</h2>
                <p v-html="record.selfIntro.replaceAll('\n', '</br>')"></p>
            </div>
        </section>
        <!-- e : 기본정보 -->

        <!-- s : 학력 -->
        <section v-if="record.eduHistOpenYn === 'Y' && record.eduHistoris.length > 0">
            <h2>학력</h2>
            <dl v-for="eduHist in record.eduHistoris">
                <dt>
                    <b>{{ $yyyymm(eduHist.startYm) }} ~ {{ eduHist.eduTenureGb !== '03' ? $yyyymm(eduHist.endYm) : ''}}</b>
                    {{ $gbNameKR('SCHOOL_GB',eduHist.schoolGb) }} {{ $gbNameKR('EDU_TENURE_GB',eduHist.eduTenureGb) }}
                </dt>
                <dd>
                    <b>{{ eduHist.schoolName }}</b>
                    <p v-if="eduHist.schoolGb !== '05'">주전공 {{ $gbNameKR('MAJOR_GB', eduHist.majorGb) }} {{ eduHist.majorName }}</p>
                    <p v-if="eduHist.submajorName">{{ $gbNameKR('SUBMAJOR_TYPE_GB', eduHist.submajorTypeGb) }} {{ $gbNameKR('MAJOR_GB', eduHist.submajorGb) }} {{ eduHist.submajorName }}</p>
                    <p v-if="eduHist.schoolGb !== '05'">{{ eduHist.score }} / {{ $gbNameKR('FULL_SCORE_GB', eduHist.fullScoreGb) }} 만점</p>
                    <p class="file" v-if="eduHist.transcriptName" @click="$downloadForPrivate({fileName:eduHist.transcriptName, fileDir: eduHist.transcriptDir })">{{ eduHist.transcriptName }}
                        <span v-if="eduHist.transcriptSize">({{ $fileSize(eduHist.transcriptSize) }})</span>
                    </p>
                </dd>
            </dl>
        </section>
        <!-- e : 학력 -->

        <!-- s : 포트폴리오 -->
        <section v-if="record.portfolioOpenYn === 'Y' && record.portfolios.length > 0">
            <h2>포트폴리오</h2>
            <dl v-for="pofol in record.portfolios">
                <dt>
                    <b>{{ $yyyymm(pofol.startYm) }} {{ pofol.pofolGb !== '01' && pofol.pofolGb !== '04' ? '~' : '' }} {{ pofol.pofolGb !== '01' && pofol.pofolGb !== '04' ? $yyyymm(pofol.endYm) : '' }}</b>
                </dt>
                <dd>
                    <b>{{ $gbNameKR('POFOL_GB', pofol.pofolGb) }}</b>
                    <p>{{ pofol.pofolName }}</p>
                    <p>{{ pofol.pofolOrgan }}</p>
                    <p v-if="pofol.pofolGb !== '01'" v-html="pofol.pofolContent.replaceAll('\n', '<br/>')"></p>
                    <p class="file" v-for="portfolioAttach in pofol.portfolioAttachs" @click="$downloadForPrivate(portfolioAttach)">
                        {{ portfolioAttach.fileName }}<span v-if="portfolioAttach.fileSize">({{ $fileSize(portfolioAttach.fileSize)}})</span>
                    </p>
                </dd>
            </dl>
        </section>
        <!-- e : 포트폴리오 -->

        <!-- s : 자격증 및 외국어 -->
        <section v-if="record.certForeignOpenYn === 'Y' && (record.certificates.length > 0 || record.languageTests.length > 0 || record.foreignLanguages.length > 0)">
            <h2>자격증 및 외국어</h2>
            <div v-if="record.certificates.length > 0">
                <h3>자격증</h3>
                <dl v-for="cert in record.certificates">
                    <dt>
                        {{ cert.getYy }}
                    </dt>
                    <dd>
                        <b>{{ cert.certName }}</b>
                        <p>{{ cert.certOrgan }}</p>
                    </dd>
                </dl>
            </div>
            <div v-if="record.languageTests.length > 0">
                <h3>어학 시험</h3>
                <dl v-for="languageTest in record.languageTests">
                    <dt>
                        {{ $yyyymm(languageTest.getYmd) }}
                    </dt>
                    <dd>
                        <b>{{ $gbNameKR('LANG_GB', languageTest.langGb) }}</b>
                        <p>시험 종류: {{ languageTest.testType }} / 점수: {{ languageTest.testScore }}</p>
                    </dd>
                </dl>
            </div>
            <div v-if="record.foreignLanguages.length > 0">
                <h3>외국어</h3>
                <dl v-for="foreignLanguage in record.foreignLanguages">
                    <dt>
                        <b>{{ foreignLanguage.foreignLangName }}</b>
                    </dt>
                    <dd>
                        <p>{{ $gbNameKR('LANG_LEVEL_GB', foreignLanguage.langLevelGb) }}</p>
                    </dd>
                </dl>
            </div>
        </section>
        <!-- e : 자격증 및 외국어 -->

        <!-- s : 업무 경험 -->
        <section v-if="record.jobHistOpenYn === 'Y' && record.jobHistorys.length > 0">
            <h2>업무 경험</h2>
            <dl v-for="jobHist in record.jobHistorys">
                <dt>
                    <b>{{ jobHist.companyName }}</b>
                    {{ $yyyymm(jobHist.startYm) }} ~ {{ jobHist.tenureYn === 'Y' ? '재직중' : $yyyymm(jobHist.endYm) }}
                </dt>
                <dd>
                    <p>{{ jobHist.jobTitle }} <span>{{ jobHist.departName }}</span></p>
                    <p v-html="jobHist.jobNote.replaceAll('\n', '</br>')"></p>
                </dd>
            </dl>
        </section>
        <!-- e : 업무 경험 -->

        <!-- s : 병역 -->
        <section class="army" v-if="record.milOpenYn === 'Y' && record.milServiceGb">
            <dl>
                <dt><h2>병역</h2></dt>
                <dd><p>{{ $gbNameKR('MIL_SERVICE_GB', record.milServiceGb) }}</p></dd>
            </dl>
        </section>
        <!-- e : 병역 -->
    </div>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.modal-body {
    .resume-wrap {
        background-color: $gray-thin_color;
        padding: 30px;
        .self-info {
            border-bottom: 2px solid $black_color;
            h1 {
                @include font-size_xl;
                margin-bottom: 10px;
            }
            p {
                @include font-size_md;
                margin-bottom: 20px;
                span {
                    position: relative;
                    margin-left: 20px;
                    padding-left: 20px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 1px;
                        height: 14px;
                        background: $gray-regular_color;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                    &:first-child {
                        padding: 0;
                        margin: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        section {
            border-bottom: 1px solid $black_color;
            padding: 30px 0;
            &:last-child {
                border-bottom: 0;
                padding: 30px 0;
            }
            &.basic {
                div {
                    border-top: $border_line;
                    padding-top: 30px;
                    h2 {
                        margin-bottom: 10px;
                    }
                    p {
                        word-break: break-word;
                    }
                }
            }
            &.army {
                h2 {
                    color: $black_color;
                }
            }
            h2 {
                @include font-size_md;
                margin-bottom: 20px;
            }
            dl {
                display: flex;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                dt {
                    width: 192px;
                    color: $gray_color;
                    b {
                        display: block;
                        color: $black_color;
                        margin-bottom: 6px;
                    }
                }
                dd {
                    width: calc(100% - 192px);
                    b {
                        display: block;
                        margin-bottom: 6px;
                    }
                    p {
                        span {
                            position: relative;
                            padding-left: 10px;
                            margin-left: 10px;
                            &:before {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 1px;
                                height: 10px;
                                background: $gray-regular_color;
                                -webkit-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .file {
                        color: $blue_color;
                        cursor: pointer;
                    }
                }
            }
            div {
                padding-bottom: 20px;
                border-bottom: $border_line;
                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                h3 {
                    @include font-size_md;
                    color: $gray_color;
                    margin-top: 20px;
                    margin-bottom: 6px;
                }
            }
        }
    }
}
</style>