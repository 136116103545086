<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineProps, defineExpose, toRefs, onMounted } from "vue";
    import { useRouter } from 'vue-router'
    import mypageRecordService from "../../../services/mypage/mypageRecordService";
    import _ from "lodash";
    import Loading from 'vue3-loading-overlay';
    import MyPageResumeModalBody from "../mypage/MyPageResumeModalBody.vue";

    const router = useRouter();

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const modalBody = ref(null)
    const alertModal = ref(null)
    const confirmModal = ref(null)
    const modalContent = ref([])

    const loaded = ref(false)

    const props = defineProps({
        showButtons: {
            type: Boolean,
            default: true,
        },
    });

    const { showButtons } = toRefs(props);

    const retrieveRecordDetail = () => {
        loaded.value = false;
        mypageRecordService.retrieveRecord()
            .then( (res) => {
                loaded.value = true;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    modalBody.value.setRecord(result);
                }
            }).catch( (error) =>  {
            loaded.value = true;
            console.log(error);
        });
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const goRecordView = () => {
        cancel();
        router.push({name: 'MyPageRecord'});
    }

    const confirm = async () => {
        modalContent.value = ["제출 후에는 수정이 불가능합니다.", "계속 하시겠습니까?"];
        const ok = await confirmModal.value.show()
        if(ok){
            baseModal.value.close();
            modalContent.value = ["선발 후 안내 예정입니다.", "별도 연락을 드리겠습니다."];
            const result = await alertModal.value.show();
            if(result){
                resolvePromise.value(true);
            }
        }
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    defineExpose({
        show,
        retrieveRecordDetail,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loaded"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-body" v-show="loaded">
                <MyPageResumeModalBody ref="modalBody"/>
            </div>
            <div v-if="showButtons" class="modal-footer btn-wrap">
                <KSASecondaryButton text="수정하기" @click="goRecordView" />
                <KSAPrimaryButton text="신청완료" class="ml-xs" @click="confirm" />
            </div>
        </div>
    </Modal>
    <AlertModal ref="alertModal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="네" cancelButtonText="아니오" />

</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
    margin-top: 50px;
    .close {
        top: -20px !important;
    }
    .modal-footer {
        display: block;
        padding: 0 30px;
    }
}
</style>