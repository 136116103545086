<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>학습현황</h3>
            </div>
            <div class="modal-body">
                <div class="list-top">
                    <p class="text-left">
                        <b>유형</b>
                    </p>
                </div>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="200px" />
                            <col />
                            <col width="200px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>회원유형</th>
                            <td colspan="3">
                                {{ lectDetail.userGbName }}
                            </td>
                        </tr>
                        <tr>
                            <th>회원 코드</th>
                            <td>{{ lectDetail.userId }}</td>
                            <th>이름</th>
                            <td>{{ lectDetail.userName }}</td>
                        </tr>
                        <tr>
                            <th>아이디</th>
                            <td>{{ lectDetail.userLoginId }}</td>
                            <th>진도율</th>
                            <td>{{ lectDetail.progRate }}%</td>
                        </tr>
                        <!--<tr>
                            <th>진행단계평가</th>
                            <td>20점</td>
                            <th>최종평가</th>
                            <td>미응시</td>
                        </tr>-->
                        <tr>
                            <th>수료 여부</th>
                            <td colspan="3">
                                {{ lectDetail.finYn === 'Y' ? '수료'
                                 : lectDetail.finYn === 'N' ? '미수료'
                                 : lectDetail.finYn === 'D' ? '수강중'
                                 : '-' }}
                            </td>
                        </tr>
                        <tr>
                            <th>수료증</th>
                            <td>
                                <span v-if="lectDetail.lectStateGb === '01'">수강대기</span>
                                <span v-else-if="lectDetail.lectStateGb === '02'">수강중</span>
                                <span v-else-if="lectDetail.finYn === 'D'">수강중</span>
                                <span v-else-if="lectDetail.finYn === 'N'">미수료</span>
                                <span v-else-if="lectDetail.finYn === 'Y'">
                                    <KSATextButton text="수료증 발급" @click="onClickRequestCert"/>
                                </span>
                                <span v-else>-</span>
                            </td>
                            <th>만족도 평가</th>
                            <td>
                                <span v-if="surveyYn">
                                    <KSATextButton text="평가 보기" @click="onClickSurveyPaperUser"/>
                                </span>
                                <span v-else>평가 전</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="list-top">
                    <p class="text-left">
                        <b>학습현황</b>
                    </p>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>차시</th>
                            <th>차시명</th>
                            <th>시간</th>
                            <th>학습상태</th>
                        </tr>
                        <tr v-for="lectIndex in lectIndexList">
                            <td>{{ lectIndex.idxNum }}차시</td>
                            <td class="text-left">{{ lectIndex.idxName }}</td>
                            <td>
                                <span v-if="lectDetail.lectGb === '02'">{{ lectIndex.idxHms }}시간</span>
                                <span v-else-if="lectIndex.movPlayHm">{{ $movPlayHm(lectIndex.movPlayHm) }}</span>
                                <span v-else>{{ lectIndex.uploadFileKey ? '인코딩 중' : '-' }}</span>
                            </td>
                            <td>
                                <span v-if="lectDetail.lectGb === '02'">-</span>
                                <span v-else-if="!lectIndex.realPlaytime">학습 전</span>
                                <span v-else-if="lectIndex.idxFinishYn !== 'Y'">학습중</span>
                                <span v-else-if="lectIndex.playtimePercent >= 100 || lectIndex.idxFinishYn === 'Y'">학습 완료</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </Modal>
    <AlertModal ref="modal" :content="modalContent" />
    <LectureSurveyPreviewModal ref="surveyPreviewModal"/>
</template>


<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, watch, onMounted,  computed, defineExpose, getCurrentInstance, toRaw } from "vue";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import lectureUserService from "../../../../user/services/lecture/lectureUserService";
    import {useCertificateStore} from "../../../../user/stores/mypage/certificateStore";
    import LectureSurveyPreviewModal from "./LectureSurveyPreviewModal.vue";

    const loadingStore = usePageLoadingStore()
    const { $gbNameKR, $userGbKR } = getCurrentInstance().appContext.config.globalProperties;

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const isSelectedAll = ref(false)

    const lectDetail = ref({})
    const lectIndexList = ref([])
    const user = ref({})
    let surveyYn = false;

    const modal = ref(null)
    const surveyPreviewModal = ref(null)
    const modalContent = ref([])

    const loaded = ref(false)

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);
    })

    // 수강정보 조회
    const retrieveLectureLearningStatus = (userId, lectId, regDt) => {
        loadingStore.loading = true;
        if(userId && lectId && regDt) {
            lectureUserService.retrieveLectureLearningStatus(userId, lectId, regDt)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        lectDetail.value = result;
                        lectDetail.value.userGbName = $userGbKR(lectDetail.value.userGb);
                        if(result.surveyDt !== null) {
                            surveyYn = true; // 만족도평가 여부 변경
                        }
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        } else {
            $toast('', '새로고침 후 다시 실행해주세요.');
        }
    }

    // 수강차시정보 조회
    const retrieveUserLectureAllIndex = (lectId, userId) => {
        loaded.value = false;
        lectureUserService.retrieveUserLectureAllIndex(lectId, userId)
            .then( (res) => {
                loaded.value = true;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectIndexList.value = result;
                }
            }).catch( (error) =>  {
            loaded.value = true;
            console.log(error);
        });
    }

    // 수료증 발급
    const onClickRequestCert = () => {
        // todo 1.만족도 평가 완료 여부 체크
        if(!surveyYn) {
            modalContent.value = ["만족도 평가를 제출하지 않아 수료증을 발급할 수 없습니다."];
            modal.value.show();
            return false;
        }
        // todo 2.수료 여부 체크
        if(!(lectDetail.value.lectStateGb === '03' && lectDetail.value.finYn === 'Y')) {
            modalContent.value = ["해당 강의를 수료하지 못했습니다."];
            modal.value.show();
            return false;
        }

        const certStore = useCertificateStore();

        user.value.userCd = lectDetail.value.userCd;
        user.value.userName = lectDetail.value.userName;
        user.value.birthYmd = lectDetail.value.birthYmd;
        user.value.school = lectDetail.value.school;
        certStore.certificate.lectDetail = _.cloneDeep(toRaw(lectDetail.value));
        certStore.certificate.lectIndexes = _.cloneDeep(toRaw(lectIndexList.value));
        certStore.certificate.userDetail = _.cloneDeep(toRaw(user.value));
        window.open('/mypage/lecture/certificate', '_blank');
    }

    // 만족도 평가 보기
    const onClickSurveyPaperUser = () => {
        surveyPreviewModal.value.init(lectDetail.value.userId, lectDetail.value.lectId, lectDetail.value.regDt, lectDetail.value.surveyId)
        surveyPreviewModal.value.show();
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value();
    };

    defineExpose({
        show,
        retrieveLectureLearningStatus,
        retrieveUserLectureAllIndex
    })

</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.list-top {
    p {
        margin-right: 0;
        padding-left: 2px;
        &:first-of-type {
            padding-left: 0;
        }
    }
}

</style>